import DateFnsUtils from '@date-io/moment'; // choose your lib
import Tooltip from '@material-ui/core/Tooltip';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import classnames from 'classnames';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';
import moment from 'moment';
import React, { MutableRefObject, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Link } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { Chip, makeStyles, MenuItem } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { useAsyncResult } from 'react-use-async-result';
import { hospitalApi, patientApi } from '../api';
import {
  ClinicResponse,
  CreatePatientFromClinicianRequest,
  CreatePatientFromClinicianRequestSubscriptionTypeEnum,
  RxFeatureFlagsResponse,
} from '../api/generated/models';
import { validate } from '../components/formValidator';
import { Input } from '../components/input';
import { useStore, useStoreDispatch } from '../context';
import { Trans, useTranslation } from '../i18n';
import '../index.scss';
import { ANALYTICS_EVENTS, track } from '../analytics';
import { NutrientDef, nutrientGetDef } from '../pages/nutrients';
import { PatientService, SUBSCRIPTION_NAME_MAP } from '../services/patient';
import { getSubscriptionTypeOptions } from '../utils/userDataTranslations';
import coaching_tab_hide from './images/app-settings-mockups/coaching-tab-hide.png';
import coaching_tab_show from './images/app-settings-mockups/coaching-tab-show.png';
import diet_score_show from './images/app-settings-mockups/diet-score-show.png';
import frame from './images/app-settings-mockups/frame.png';
import hide_meal_after_n_hours_show from './images/app-settings-mockups/hide-meal-after-n-hours.png';
import macro_summary_hide from './images/app-settings-mockups/macro-summary-hide.png';
import macro_summary_show_total_carbs from './images/app-settings-mockups/macro-summary-show-total-carbs.png';
import macro_summary_show_net_carbs from './images/app-settings-mockups/macro-summary-show.png';
import mindful_eating_hide from './images/app-settings-mockups/mindful-eating-hide.png';
import mindful_eating_show from './images/app-settings-mockups/mindful-eating-show.png';
import nutritional_insights_hide from './images/app-settings-mockups/nutrition-insights-hide.png';
import nutritional_insights_show from './images/app-settings-mockups/nutrition-insights-show.png';
import primary_macro_display_cals from './images/app-settings-mockups/primary-macro-cals.png';
import primary_macro_display_net_carbs from './images/app-settings-mockups/primary-macro-carbs.png';
import primary_macro_display_none from './images/app-settings-mockups/primary-macro-none.png';
import primary_macro_display_total_carbs from './images/app-settings-mockups/primary-macro-total-carbs.png';
import { LoadingSpinner } from './loadingSpinner';
import { MuiCustomDatePicker, MuiCustomSelect, MuiCustomTextField } from './muiCustomInput';
import { disableDatesNotInMultiple, disablePastDates, getDiffByTrialType, getWeeksToAdd } from './sendLoggingTrial';
import { StyledTooltip } from './StyledTooltip';

const imagePathsMappings = {
  patient_app_show_macro_nutrition_card_hide: macro_summary_hide,
  patient_app_show_macro_nutrition_card_show_net_carbs: macro_summary_show_net_carbs,
  patient_app_show_macro_nutrition_card_show_total_carbs: macro_summary_show_total_carbs,
  patient_app_show_diet_score_hide: nutritional_insights_show,
  patient_app_show_diet_score_show: diet_score_show,
  patient_app_show_nutrition_data_and_insights_hide: nutritional_insights_hide,
  patient_app_show_nutrition_data_and_insights_show: nutritional_insights_show,
  patient_app_mindful_eating_survey_hide: mindful_eating_hide,
  patient_app_mindful_eating_survey_show: mindful_eating_show,
  patient_app_coaching_tab_hide: coaching_tab_hide,
  patient_app_coaching_tab_show: coaching_tab_show,
  patient_app_hide_meal_after_n_hours_hide: primary_macro_display_none,
  patient_app_hide_meal_after_n_hours_show: hide_meal_after_n_hours_show,
};
export type HospitalPatientCustomField = {
  label: string,
  field: string,
  values?: {
    [key: string]: {
      label: string, // Display value
      value: string, // Stored value
      description?: string, // Optional description for tooltip
    },
  },
};

export const clinicGetHospitalPatientCustomFields = (
  clinic: ClinicResponse | null,
): null | Array<HospitalPatientCustomField> => {
  const customFieldsJson = clinic?.flags?.hospital_patient_custom_fields_json;
  if (!customFieldsJson) {
    return null;
  }
  try {
    return JSON.parse(customFieldsJson);
  } catch (e) {
    console.error('Failed to parse hospital_patient_custom_fields_json', e);
    return null;
  }
};

const ClinicPatientCustomFieldsInput = (props: {
  clinic: ClinicResponse,
  value: Record<string, any> | null,
  onChange: (value: Record<string, any>) => void,
}) => {
  const { clinic } = props;
  const { t } = useTranslation();
  const customFields: null | Array<HospitalPatientCustomField> = React.useMemo(() => {
    return clinicGetHospitalPatientCustomFields(clinic);
  }, [clinic]);

  const onChange = (field: string, value: any) => {
    props.onChange({
      ...(props.value || {}),
      [field]: value,
    });
  };

  if (!customFields) {
    return null;
  }

  if (!Array.isArray(customFields)) {
    console.error('hospital_patient_custom_fields_json is not an array', customFields);
    return null;
  }

  return (
    <>
      {customFields.map(field => (
        <Row key={field.field}>
          <Col className="verticalCenter">
            {field.values
              ? (
                <Form.Group>
                  <Form.Label>{field.label}</Form.Label>
                  <Form.Control
                    as="select"
                    value={props.value?.[field.field] || ''}
                    onChange={(e) => onChange(field.field, (e.target as HTMLSelectElement).value)}
                    size="lg"
                  >
                    <option value="">{t('Select an option')}</option>
                    {Object.entries(field.values).map(([key, option]) => (
                      <option key={key} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Control>
                  {field.values[props.value?.[field.field]]?.description && (
                    <Form.Text className="text-muted">
                      {field.values[props.value?.[field.field]].description}
                    </Form.Text>
                  )}
                </Form.Group>
              )
              : (
                <Input
                  label={field.label}
                  background
                  value={props.value?.[field.field] || ''}
                  onChange={e => onChange(field.field, e.target.value)}
                  size="lg"
                />
              )}
          </Col>
        </Row>
      ))}
    </>
  );
};

export const InviteCodeModal = (props: {
  isOpen: boolean,
  toggle: () => void,
}) => {
  const { clinician } = useStore();
  const invitationCodesQueryResults = useQueries({
    queries: clinician.clinics.map((clinic) => {
      return {
        queryKey: ['invitation-code', clinic.id, clinic.hospital_id],
        queryFn: () =>
          hospitalApi.appApiHospitalGetClinicInvitationCodesDefault({
            hospital_id: clinic.hospital_id,
            clinic_id: clinic.id,
          }),
        enabled: props.isOpen,
      };
    }),
  });
  const invitationCodes = invitationCodesQueryResults.map(q => q.data);

  return (
    <MDBContainer>
      <MDBModal backdrop={false} isOpen={props.isOpen} toggle={props.toggle} centered size="lg">
        <MDBModalHeader toggle={props.toggle}>
          <Trans>Clinic invitation code</Trans>
        </MDBModalHeader>
        <MDBModalBody>
          <Container>
            {invitationCodesQueryResults.some(q => q.isLoading)
              ? <LoadingSpinner />
              : (invitationCodesQueryResults.some(q => q.isError)
                ? (
                  <div>
                    <Trans>Unexpected error loading invitation code:</Trans>{' '}
                    {'' + invitationCodesQueryResults.some(q => q.isError)}
                  </div>
                )
                : (
                  <>
                    {clinician.clinics.map(clinic => (
                      <div key={clinic.id} style={{ marginBottom: '5px' }}>
                        <span>{clinic.name}</span>
                        {': '}
                        <span style={{ letterSpacing: '0.1rem' }}>
                          {invitationCodes.find(c => c.data.clinic_id == clinic.id).data.invitation_code}
                        </span>
                      </div>
                    ))}
                  </>
                ))}
          </Container>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

export const AddUser = (props: {
  mode?: 'add' | 'edit' | 'waitAdd' | 'waitEdit',
  show: boolean,
  toggle: () => void,
  customize?: MutableRefObject<boolean>,
  subscriptionId?: MutableRefObject<number>,
  patientId?: MutableRefObject<number>,
  trackingStartDate?: React.MutableRefObject<moment.Moment | null>,
  filteredClinics?: number[],
}) => {
  const { mode = 'add', toggle, show, customize, subscriptionId, patientId, trackingStartDate } = props;
  let { patient } = useStore();
  const { clinician } = useStore();
  const { setPatient } = useStoreDispatch();
  const service = PatientService();
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  if (mode == 'add') {
    patient = {} as any;
  }

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const [firstName, setFirstName] = useState(patient.first_name || '');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState(patient.last_name || '');
  const [lastNameError, setLastNameError] = useState('');
  const [email, setEmail] = useState(patient.email || '');
  const [emailError, setEmailError] = useState('');
  const [patientExternalId, setPatientExternalId] = useState<string | null>(
    patient.hospital_attributes?.patient_external_id || null,
  );
  const [patientCustomFields, setPatientCustomFields] = useState<Record<string, any> | null>(
    patient.hospital_attributes?.patient_custom_fields || null,
  );
  const [startDate, setStartDate] = useState(moment().add(1, 'days'));
  const [startDateError, setStartDateError] = useState('');
  const [clinicId, setClinicId] = useState<string | number>('');
  const [clinicError, setClinicError] = useState('');
  const [phone, setPhone] = useState(patient.phone_number || '');
  const [phoneError, setPhoneError] = useState('');
  const [displayMode, setDisplayMode] = useState(mode);
  const [trialDays, setTrialDays] = useLocalStorage('add-patient:default-trial-days', 3);
  // eslint-disable-next-line i18next/no-literal-string
  const [trialType, setTrialType] = useState('');
  const [multipleTrialEndDate, setMultipleTrialEndDate] = useState<string>('');

  const [reportDate, setReportDate] = useState<moment.Moment | null>(moment().add(3, 'months'));
  const [reportDateError, setReportDateError] = useState('');
  const [patientFlags, setPatientFlags] = useState<Partial<RxFeatureFlagsResponse> | null>({});

  const saveRes = useAsyncResult();

  const patientService = PatientService();
  const patientPrimaryClinicReq = useAsyncResult<ClinicResponse>(async () => {
    if (!patient?.patient_id) {
      return null;
    }
    const clinics = await patientService.getPatientClinics(patient.patient_id);
    return clinics[0];
  }, [patient?.patient_id]);

  const patientReq = useAsyncResult(async () => {
    if (!patient?.patient_id) {
      return null;
    }
    const res = await patientApi.appApiPatientGetPatient({ patient_id: patient.patient_id });
    return res.data;
  }, [patient?.patient_id]);

  useEffect(() => {
    if (!patientReq.isDone || !patientReq.result) {
      setPatientFlags({});
      return;
    }

    const p = patientReq.result;
    setPatientFlags(p.flags_raw || {});
  }, [patientReq]);

  const validator = {
    firstName: () => {
      setFirstNameError('');
      if (!firstName) {
        setFirstNameError(t('Please enter a first name'));
        return false;
      }
      return true;
    },
    lastName: () => {
      setLastNameError('');
      if (!lastName) {
        setLastNameError(t('Please enter a last name'));
        return false;
      }
      return true;
    },
    email: () => {
      setEmailError('');
      const emailValid = email.match(/^([\w.%+-]+)@([\w-]+)(\.[\w-]+)+$/i);
      if (!email) {
        setEmailError(t('Please enter an email'));
        return false;
      }
      if (!emailValid) {
        setEmailError(t('Please enter a valid email'));
        return false;
      }
      return true;
    },
    phone: () => {
      setPhoneError('');
      const phoneClean = phone.replace(/\D/g, '');
      if (phoneClean.length > 0 && phoneClean.length != 10) {
        setPhoneError(t('Please enter a valid phone number'));
        return false;
      }
      setPhone(phoneClean);
      return true;
    },
    startDate: () => {
      setStartDateError('');
      if (!startDate && trialDays > 0) {
        setStartDateError(t('Please enter a start date'));
        return false;
      }
      return true;
    },
    clinic: () => {
      setClinicError('');
      if (!clinicId) {
        setClinicError(t('Please select a clinic'));
        return false;
      }
      return true;
    },
    reportDate: () => {
      setReportDateError('');
      if (!reportDate && trialDays > 0) {
        setReportDateError(t('Please select a date to receive the report'));
        return false;
      }
      return true;
    },
  };

  // Patient info gets reset to last correct values when editing patient info or closing new patient modal
  const reset = () => {
    setFirstName(firstName);
    setFirstNameError('');
    setLastName(lastName);
    setLastNameError('');
    setEmail(email);
    setEmailError('');
    setPatientExternalId(
      patient.hospital_attributes?.patient_external_id || null,
    );
    setPatientCustomFields(
      patient.hospital_attributes?.patient_custom_fields || null,
    );
    setPhone(phone);
    setPhoneError('');
    setClinicId('');
    setClinicError('');
    setReportDateError('');
    setStartDate(moment().add(1, 'days'));
    setReportDate(moment().add(3, 'months'));
  };

  // Patient Info is cleared after successful submit of new patient
  const clear = () => {
    setFirstName('');
    setFirstNameError('');
    setLastName('');
    setLastNameError('');
    setEmail('');
    setEmailError('');
    setPatientExternalId(null);
    setPatientCustomFields(null);
    setPhone('');
    setPhoneError('');
    setClinicId('');
    setClinicError('');
    setReportDateError('');
    setStartDate(moment().add(1, 'days'));
    setReportDate(moment().add(3, 'months'));
    setTrialType('');
    setMultipleTrialEndDate('');
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePatientExternalIdChange = (e) => {
    setPatientExternalId(e.target.value);
  };
  const handlePatientCustomFieldsChange = (value: Record<string, any>) => {
    setPatientCustomFields(value);
  };
  const handlePhoneChange = (e) => {
    if (!e.target.value) {
      setPhone(e.target.value);
    }
    // only allows 0-9 inputs
    const currentValue = e.target.value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;
    if (cvLength == 0) {
      setPhone('');
    } else if (cvLength > 0 && cvLength < 4) {
      setPhone(currentValue);
    } else if (cvLength < 7) {
      setPhone('(' + currentValue.slice(0, 3) + ') ' + currentValue.slice(3));
    } else {
      setPhone('(' + currentValue.slice(0, 3) + ') ' + currentValue.slice(3, 6) + ' - ' + currentValue.slice(6, 10));
    }
  };

  const clinic = clinician.clinics?.find(c => c.id == clinicId);

  const trialTypesFromFlag = clinic?.flags?.clinic_subscription_types?.split(',').map(type => type.trim())
    || ['single'];
  const trialTypeOptions = [...trialTypesFromFlag, 'none'];

  // Desired order of trial types:
  const trialTypeOrder = [
    'single',
    // eslint-disable-next-line i18next/no-literal-string
    'weekly',
    // eslint-disable-next-line i18next/no-literal-string
    'bi-weekly',
    '4-weeks',
    'monthly',
    'none', // corresponds to "no initial logging period"
  ];

  // Filter and order the available trial types:
  const orderedTrialTypes = trialTypeOrder.filter((type) => trialTypeOptions?.includes(type));

  useEffect(() => {
    if (orderedTrialTypes.length > 0) {
      setTrialType(orderedTrialTypes[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic?.flags?.clinic_subscription_types]);

  useEffect(() => {
    if (trialType === 'single') {
      return;
    }
    if (trialType === 'weekly') {
      setTrialDays(7);
    } else if (trialType === 'bi-weekly') {
      setTrialDays(14);
    } else if (trialType === '4-weeks') {
      setTrialDays(28);
    } else if (trialType === 'monthly') {
      setTrialDays(30);
    }
  }, [trialType, setTrialDays]);

  const handleStartDateChange = (e) => {
    const newStartDate = moment(e._d);
    setStartDate(newStartDate);
  };
  const handleReportDateChange = (e) => {
    setReportDate(moment(e._d));
  };
  const handleClinicChange = (e) => {
    if (typeof e[0]?.id === 'number') {
      const clinicId = parseInt(e[0]?.id);
      setClinicId(clinicId);
      patientPrimaryClinicReq.bind(Promise.resolve(clinician.clinics.find(c => c.id == clinicId)));
    }
  };
  function disableInvalidReportDates(date) {
    const offset = clinician?.flags?.clinician_report_24h_turnaround ? 1 : 2;
    return date < moment(startDate).add(trialDays + offset, 'days');
  }

  const availableTrialLengths = clinic?.flags?.clinician_available_trial_lengths || '3,5';
  const trialList = [
    // eslint-disable-next-line i18next/no-literal-string
    ...availableTrialLengths.split(',').map(bit => bit.trim()),
  ];
  useEffect(() => {
    if (clinic?.flags?.clinician_available_trial_lengths) {
      setTrialDays(Number(trialList[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic?.flags?.clinician_available_trial_lengths]);

  async function addUserCalls() {
    let res = null;

    const flagsRaw: Partial<RxFeatureFlagsResponse> = {
      patient_show_cals: patientFlags?.patient_show_cals,
      patient_show_carbs: patientFlags?.patient_show_carbs,
      patient_app_show_diet_score: patientFlags?.patient_app_show_diet_score,
      patient_app_show_macro_nutrition_card: patientFlags?.patient_app_show_macro_nutrition_card,
      patient_app_show_nutrition_data_and_insights: patientFlags?.patient_app_show_nutrition_data_and_insights,
      patient_app_coaching_tab: patientFlags?.patient_app_coaching_tab,
      patient_app_mindful_eating_survey: patientFlags?.patient_app_mindful_eating_survey,
      patient_app_key_nutrients: patientFlags?.patient_app_key_nutrients,
      patient_app_hide_meal_after_n_hours: patientFlags?.patient_app_hide_meal_after_n_hours,
    };

    if (displayMode == 'add') {
      if (
        !validate(
          validator.firstName,
          validator.lastName,
          validator.email,
          validator.clinic,
          validator.startDate,
          validator.reportDate,
        )
      ) {
        return;
      }
      // eslint-disable-next-line i18next/no-literal-string
      setDisplayMode('waitAdd');
      trackingStartDate.current = startDate;

      const patientInfo: CreatePatientFromClinicianRequest = {
        clinic_id: +clinicId,
        hospital_patient_external_id: patientExternalId,
        hospital_patient_custom_fields: patientCustomFields,
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone_number: phone,
        flags_raw: flagsRaw,
      };

      if (trialType == 'none') {
        res = await service.addPatient({ ...patientInfo, subscription_name: 'none' });
      } else {
        const reqBody = {
          ...patientInfo,
          tracking_start_date: startDate.format('YYYY-MM-DD'),
          subscription_expiration_date: (trialType !== 'single' && multipleTrialEndDate) || undefined,
          subscription_type: trialType as CreatePatientFromClinicianRequestSubscriptionTypeEnum,
          first_report_date: (trialType !== 'single' && multipleTrialEndDate)
            ? undefined
            : reportDate?.format('YYYY-MM-DD'),
          subscription_name: ({ '0': 'none', ...SUBSCRIPTION_NAME_MAP } as const)[trialDays.toString()],
        };
        res = await service.addPatient(reqBody);
      }
      await service.getPatientList(clinician.hospital_id, clinician.id, true);
    } else if (displayMode == 'edit') {
      if (!validate(validator.firstName, validator.lastName, validator.email, validator.phone)) {
        return;
      }
      // eslint-disable-next-line i18next/no-literal-string
      setDisplayMode('waitEdit');
      const hospital_attributes = !(patientExternalId && patientCustomFields) ? null : {
        hospital_id: clinician.hospital_id,
        patient_external_id: patientExternalId,
        patient_custom_fields: patientCustomFields,
      };
      res = await service.updatePatient(
        patient.patient_id,
        firstName,
        lastName,
        email,
        phone,
        flagsRaw,
        hospital_attributes,
      );
      console.log('Update ' + res);
      setPatient({
        ...patient,
        ...res,
        hospital_attributes,
        patient_id: res.id,
      });
    }
    toggle();

    if (res) {
      track(
        displayMode === 'add' || displayMode === 'waitAdd'
          ? ANALYTICS_EVENTS.PATIENT_ADDED
          : ANALYTICS_EVENTS.PATIENT_EDITED,
        {
          'Patient ID': patient.patient_id,
          'Clinic ID': clinicId,
          'Start Date': startDate?.format('YYYY-MM-DD'),
          'Trial Type': trialType,
          'Trial Days': trialDays,
          'Report Date': reportDate?.format('YYYY-MM-DD'),
        },
      );
      displayMode === 'add' ? clear() : reset();
      // eslint-disable-next-line i18next/no-literal-string
      queryClient.invalidateQueries(['patient-table']);
    }
  }

  const handleButtonClick = () => {
    saveRes.bind(
      addUserCalls()
        .then(() => {
          setDisplayMode(mode);
          console.log('done now');
        })
        .catch((err) => {
          setDisplayMode(mode);
          console.log('Save patient error:', err);
          throw err;
        }),
    );
  };

  const singleTrialEndDate = React.useMemo(() => {
    return moment(startDate).add(trialDays - 1, 'days');
  }, [startDate, trialDays]);

  React.useEffect(() => {
    if (trialType === 'single') {
      setMultipleTrialEndDate('');
      return;
    }
    if (trialType === 'monthly') {
      setMultipleTrialEndDate(moment(startDate).add(1, 'month').format('YYYY-MM-DD'));
      return;
    }
    setMultipleTrialEndDate(moment(startDate).add(getWeeksToAdd(trialType), 'weeks').format('YYYY-MM-DD'));
  }, [trialType, startDate]);

  const handleMultipleTrialEndDateChange = (e: moment.Moment) => {
    setMultipleTrialEndDate(e.clone().format('YYYY-MM-DD'));
  };

  const futureStartDates = React.useMemo(() => {
    const startDateStr = DateTime.fromJSDate(startDate.toDate()).toISODate();
    if (trialType === 'single') {
      return [];
    } else if (trialType === 'monthly') {
      const diff = getDiffByTrialType(startDateStr, multipleTrialEndDate, trialType);
      return Array.from({ length: diff }, (_, i) => {
        return DateTime.fromISO(startDateStr)
          .plus({ months: i })
          .toISODate();
      });
    }
    const diff = getDiffByTrialType(startDateStr, multipleTrialEndDate, trialType);
    return Array.from({ length: diff }, (_, i) => {
      return DateTime.fromISO(startDateStr)
        .plus({ weeks: i * getWeeksToAdd(trialType) })
        .toISODate();
    });
  }, [trialType, startDate, multipleTrialEndDate]);

  const titleText = {
    add: t('Add new patient'),
    waitAdd: t('Add new patient'),
    edit: t('Edit patient'),
    waitEdit: t('Edit patient'),
  };

  const buttonText = {
    add: t('add new patient'),
    waitAdd: t('adding patient...'),
    edit: t('save changes'),
    waitEdit: t('making changes...'),
  };

  const primaryClinic = displayMode == 'add'
    ? (props.filteredClinics?.length
      ? clinician.clinics.find(c => c.id == props.filteredClinics[0])
      : clinician.clinics.find(c => c.id == clinicId))
    : patientPrimaryClinicReq.isDone && patientPrimaryClinicReq.result;

  useEffect(() => {
    if (clinician.clinics?.length === 1) {
      setClinicId(clinician.clinics[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinician.clinics?.length, clinicId]);

  useEffect(() => {
    const flagStr = primaryClinic?.flags?.clinic_default_days_until_report_date;
    const date = flagStr ? singleTrialEndDate.clone().add(Number(flagStr), 'days') : moment().add(3, 'months');
    setReportDate(date);
  }, [singleTrialEndDate, primaryClinic?.flags?.clinic_default_days_until_report_date]);

  const clinicDefaultMacroDisplay = !patientPrimaryClinicReq.isDone
    ? '...'
    : !primaryClinic
    ? t('Select a clinic')
    : primaryClinic?.flags?.patient_show_carbs
    ? t('carbohydrates')
    : primaryClinic?.flags?.patient_show_cals
    ? t('calories')
    : t('none');

  const labelValues = {
    patient_app_show_diet_score: t('diet score'),
    patient_app_show_macro_nutrition_card: t('macronutrient summary'),
    patient_app_show_nutrition_data_and_insights: t('nutritional insights'),
    patient_app_coaching_tab: t('explore tab'),
    patient_app_mindful_eating_survey: t('mindful eating survey'),
  };

  const getClinicDefault = (flag: keyof RxFeatureFlagsResponse): string =>
    !patientPrimaryClinicReq.isDone
      ? '...'
      : !primaryClinic
      ? t('Select a clinic')
      : flag === 'patient_app_hide_meal_after_n_hours'
      ? primaryClinic?.flags?.[flag]
        ? primaryClinic?.flags?.[flag] + ' h'
        : t('Off')
      : primaryClinic?.flags?.[flag]
      ? t(`show {{ value }}`, { value: labelValues[flag] })
      : t(`hide {{ value }}`, { value: labelValues[flag] });

  const getPrimaryMacro = () =>
    patientFlags.patient_show_carbs ? 'carbs' : patientFlags.patient_show_cals
      ? 'cals'
      : patientFlags.patient_show_carbs === false && patientFlags.patient_show_cals === false
      ? 'none'
      : 'unset';

  const setFlagTrueFalseUnset = (flag: keyof RxFeatureFlagsResponse, value: string) => {
    if (value === 'unset') {
      setPatientFlags({
        ...patientFlags,
        [flag]: undefined,
      });
      return;
    }
    setPatientFlags({
      ...patientFlags,
      [flag]: value === 'true',
    });
  };

  const setValueTrueFalseUnset = (flag: boolean): string => {
    return flag ? 'true' : flag === false ? 'false' : 'unset';
  };

  const setImageTrueFalseUnset = (flag: keyof RxFeatureFlagsResponse, evt: any) => {
    if (flag === 'patient_app_hide_meal_after_n_hours') {
      setImageToShow(imagePathsMappings[flag + '_show']);
      return;
    }

    // eslint-disable-next-line i18next/no-literal-string
    const value = evt.target.getAttribute('data-value') ?? setValueTrueFalseUnset(patientFlags[flag] as boolean);

    if (flag === 'patient_app_show_macro_nutrition_card') {
      if (value === 'unset') {
        primaryClinic?.flags?.[flag]
          ? primaryClinic?.flags?.patient_show_total_carbs
            ? setImageToShow(imagePathsMappings[flag + '_show_total_carbs'])
            : setImageToShow(imagePathsMappings[flag + '_show_net_carbs'])
          : setImageToShow(imagePathsMappings[flag + '_hide']);
      } else if (value === 'false') {
        setImageToShow(imagePathsMappings[flag + '_hide']);
      } else if (value) {
        patientFlags?.patient_show_total_carbs
          ? setImageToShow(imagePathsMappings[flag + '_show_total_carbs'])
          : setImageToShow(imagePathsMappings[flag + '_show_net_carbs']);
      }
      return;
    }

    if (value === 'unset') {
      Number(primaryClinic?.flags?.[flag])
        ? setImageToShow(imagePathsMappings[flag + '_show'])
        : setImageToShow(imagePathsMappings[flag + '_hide']);
    } else if (value === 'false') {
      setImageToShow(imagePathsMappings[flag + '_hide']);
    } else if (value) {
      setImageToShow(imagePathsMappings[flag + '_show']);
    }
  };

  const safeModeFlagKeys: Array<keyof RxFeatureFlagsResponse> = [
    'patient_show_carbs',
    'patient_show_cals',
    'patient_app_show_diet_score',
    'patient_app_show_nutrition_data_and_insights',
    'patient_app_coaching_tab',
    'patient_app_show_macro_nutrition_card',
  ];

  const [imageToShow, setImageToShow] = useState(frame);

  const [isSafeMode, setSafeMode] = useState<'on' | 'off' | ''>('');
  const [toggleSafeMode, setToggleSafeMode] = useState(false);
  useEffect(() => {
    if (mode === 'add') {
      const isSafeModeOn = safeModeFlagKeys.every(flag => {
        const flagToUse = patientFlags?.[flag] ?? primaryClinic?.flags?.[flag];
        return flagToUse === false;
      });
      setSafeMode(isSafeModeOn ? 'on' : 'off');
      setToggleSafeMode(isSafeModeOn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryClinic]);

  useEffect(() => {
    if (!_.isEmpty(patientFlags) && !Object.values(patientFlags).every(v => v === undefined)) {
      const isSafeModeOn = safeModeFlagKeys.every(flag => {
        const flagToUse = patientFlags?.[flag] ?? primaryClinic?.flags?.[flag];
        return flagToUse === false;
      });
      setSafeMode(isSafeModeOn ? 'on' : 'off');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientFlags]);

  const [hideMealHours, setHideMealHours] = useState<number | undefined>(undefined);
  const [hideMealHoursStatus, setHideMealHoursStatus] = useState<'unset' | 'on' | 'off'>('unset');
  useEffect(() => {
    const flagValue = patientFlags.patient_app_hide_meal_after_n_hours
      ?? primaryClinic?.flags?.patient_app_hide_meal_after_n_hours;
    const flagValueNum = Number(flagValue);
    setHideMealHours(flagValueNum ?? 0);
    if (patientFlags.patient_app_hide_meal_after_n_hours === undefined) {
      setHideMealHoursStatus('unset');
    } else if (flagValueNum > 0) {
      setHideMealHoursStatus('on');
    } else {
      setHideMealHoursStatus('off');
    }
  }, [patientFlags.patient_app_hide_meal_after_n_hours, primaryClinic?.flags?.patient_app_hide_meal_after_n_hours]);

  const [selectedNutrients, setSelectedNutrients] = useState<NutrientDef[] | null>(null);
  useEffect(() => {
    const flagValue = patientFlags.patient_app_key_nutrients
      ?? primaryClinic?.flags?.patient_app_key_nutrients;
    const nutrientNames = flagValue?.split(',').map(s => s.trim());
    setSelectedNutrients(
      nutrientNames
        ?.map(nutrientName => nutrientGetDef(nutrientName as any))
        ?.filter(nutrientDef => !nutrientDef.isUnknown)
        ?? null,
    );
  }, [patientFlags.patient_app_key_nutrients, primaryClinic?.flags?.patient_app_key_nutrients]);

  const keyNutrientOptions = React.useMemo(() => {
    return ['protein_g', 'fat_g', 'default_carbs_g', 'energy_kcal', 'fiber_g']
      .map(nutrientName => nutrientGetDef(nutrientName as any))
      .filter(nutrientDef => !nutrientDef.isUnknown);
  }, []);

  const appSettings = useMemo(
    () =>
      [
        {
          label: t('Safe mode'),
          value: isSafeMode,
          onChange: (evt: any) => {
            if (evt.target.value === 'on') {
              setPatientFlags({
                ...patientFlags,
                ...safeModeFlagKeys.reduce((obj, key) => {
                  obj[key] = false;
                  return obj;
                }, {}),
              });
              setSafeMode('on');
              setToggleSafeMode(true);
              return;
            }
            setPatientFlags({
              ...patientFlags,
              ...safeModeFlagKeys.reduce((obj, key) => {
                obj[key] = undefined;
                return obj;
              }, {}),
            });
            setSafeMode('off');
            setToggleSafeMode(false);
          },
          // show home screen in safe mode?
          onMouseOver: (evt: any) => setImageToShow(primary_macro_display_none),
          options: [
            { value: 'off', label: t('Off') },
            { value: 'on', label: t('On') },
          ],
          description: t('Turn off all app settings in the RxFood app.'),
          visible: clinician.flags?.clinician_patient_allow_cal_carb_toggle,
        },
        {
          label: t('Primary in-app macronutrient display'),
          value: getPrimaryMacro(),
          onChange: (evt: any) => {
            if (evt.target.value === 'unset') {
              setPatientFlags({
                ...patientFlags,
                patient_show_carbs: undefined,
                patient_show_cals: undefined,
              });
              return;
            }
            setPatientFlags({
              ...patientFlags,
              patient_show_carbs: evt.target.value === 'carbs',
              patient_show_cals: evt.target.value === 'cals',
            });
          },
          onMouseOver: (evt: any) => {
            const value = evt.target.getAttribute('data-value') ?? getPrimaryMacro();
            if (value === 'unset') {
              primaryClinic?.flags?.patient_show_carbs
                ? primaryClinic?.flags?.patient_show_total_carbs
                  ? setImageToShow(primary_macro_display_total_carbs)
                  : setImageToShow(primary_macro_display_net_carbs)
                : primaryClinic?.flags?.patient_show_cals
                ? setImageToShow(primary_macro_display_cals)
                : setImageToShow(primary_macro_display_none);
            } else if (value === 'carbs') {
              patientFlags?.patient_show_total_carbs
                ? setImageToShow(primary_macro_display_total_carbs)
                : setImageToShow(primary_macro_display_net_carbs);
            } else if (value === 'cals') {
              setImageToShow(primary_macro_display_cals);
            } else {
              setImageToShow(primary_macro_display_none);
            }
          },
          options: [
            {
              value: 'unset',
              label: primaryClinic
                ? t('Clinic default ({{clinicDefaultMacroDisplay}})', { clinicDefaultMacroDisplay })
                : clinicDefaultMacroDisplay,
            },
            { value: 'none', label: t('None (hide primary macronutrient)') },
            { value: 'carbs', label: t('Carbohydrates') },
            { value: 'cals', label: t('Calories') },
          ],
          description: t('This macronutrient will be displayed prominently beside each meal logged in the RxFood app.'),
          visible: clinician.flags?.clinician_patient_allow_cal_carb_toggle,
          hideInSafeMode: false,
        },
        {
          label: t('Automatically hide meals after specified time'),
          showTextInput: true,
          value: hideMealHoursStatus,
          onChange: (evt: any) => {
            const newStatus = evt.target.value;
            setHideMealHoursStatus(newStatus);
            if (newStatus === 'unset') {
              setPatientFlags({
                ...patientFlags,
                patient_app_hide_meal_after_n_hours: primaryClinic?.flags?.patient_app_hide_meal_after_n_hours
                  || String(0),
              });
            } else if (newStatus === 'off') {
              setPatientFlags({
                ...patientFlags,
                patient_app_hide_meal_after_n_hours: String(0),
              });
            } else if (newStatus === 'on') {
              setPatientFlags({
                ...patientFlags,
                patient_app_hide_meal_after_n_hours: String(hideMealHours || 0),
              });
            }
          },
          onMouseOver: (evt: any) => setImageTrueFalseUnset('patient_app_hide_meal_after_n_hours', evt),
          options: [
            {
              value: 'unset',
              label: primaryClinic
                ? t('Clinic default ({{ defaultValue }})', {
                  defaultValue: getClinicDefault('patient_app_hide_meal_after_n_hours'),
                })
                : getClinicDefault('patient_app_hide_meal_after_n_hours'),
            },
            {
              value: 'on',
              label: t('Hide meals after X hours (enter a number)'),
            },
            {
              value: 'off',
              label: t('Off'),
            },
          ],
          description: t(
            'Hide meals from view after a certain number of hours have passed since the meal was logged.',
          ),
          visible: true,
          hideInSafeMode: false,
        },
        {
          label: t('Macronutrient summary display'),
          value: setValueTrueFalseUnset(patientFlags.patient_app_show_macro_nutrition_card),
          onChange: (evt: any) => setFlagTrueFalseUnset('patient_app_show_macro_nutrition_card', evt.target.value),
          onMouseOver: (evt: any) => setImageTrueFalseUnset('patient_app_show_macro_nutrition_card', evt),
          options: [
            {
              value: 'unset',
              label: primaryClinic
                ? t('Clinic default ({{ defaultValue }})', {
                  defaultValue: getClinicDefault('patient_app_show_macro_nutrition_card'),
                })
                : getClinicDefault('patient_app_show_macro_nutrition_card'),
            },
            { value: 'false', label: t('Hide macronutrient summary') },
            { value: 'true', label: t('Show macronutrient summary') },
          ],
          description: t(
            'A macronutrient summary (protein, carbs, fat, calories etc.) by meal and day will be displayed in the RxFood app.',
          ),
          visible: clinician.flags?.clinician_patient_allow_macro_card_toggle,
          hideInSafeMode: true,
        },

        {
          label: t('Macronutrients to show in summary'),
          values: selectedNutrients || [],
          onChange: (evt: any, newValues: NutrientDef[]) => {
            if (newValues.length > 4) {
              return;
            }
            setPatientFlags({
              ...patientFlags,
              patient_app_key_nutrients: newValues.map(v => v.nutrient).join(', '),
            });
          },
          options: keyNutrientOptions,
          inputIsMultiselect: true,
          description: t('Up to 4 nutrients can be displayed in a macronutrient summary card in the RxFood app.'),
          visible: clinician.flags?.clinician_patient_allow_macro_card_toggle
            && patientFlags.patient_app_show_macro_nutrition_card,
          hideInSafeMode: true,
        },

        {
          label: t('Show Mediterranean diet score in mobile app'),
          value: setValueTrueFalseUnset(patientFlags.patient_app_show_diet_score),
          onChange: (evt: any) => setFlagTrueFalseUnset('patient_app_show_diet_score', evt.target.value),
          onMouseOver: (evt: any) => setImageTrueFalseUnset('patient_app_show_diet_score', evt),
          options: [
            {
              value: 'unset',
              label: primaryClinic
                ? t('Clinic default ({{ defaultValue }})', {
                  defaultValue: getClinicDefault('patient_app_show_diet_score'),
                })
                : getClinicDefault('patient_app_show_diet_score'),
            },
            { value: 'false', label: t('Hide diet score') },
            { value: 'true', label: t('Show diet score') },
          ],
          visible: clinician.flags?.clinician_patient_allow_cal_carb_toggle,
          hideInSafeMode: true,
        },
        {
          label: t('Show nutritional data and insights in mobile app'),
          value: setValueTrueFalseUnset(patientFlags.patient_app_show_nutrition_data_and_insights),
          onChange: (evt: any) =>
            setFlagTrueFalseUnset('patient_app_show_nutrition_data_and_insights', evt.target.value),
          onMouseOver: (evt: any) => setImageTrueFalseUnset('patient_app_show_nutrition_data_and_insights', evt),
          options: [
            {
              value: 'unset',
              label: primaryClinic
                ? t('Clinic default ({{ defaultValue }})', {
                  defaultValue: getClinicDefault('patient_app_show_nutrition_data_and_insights'),
                })
                : getClinicDefault('patient_app_show_nutrition_data_and_insights'),
            },
            { value: 'false', label: t('Hide nutritional insights') },
            { value: 'true', label: t('Show nutritional insights') },
          ],
          visible: clinician.flags?.clinician_patient_allow_cal_carb_toggle,
          hideInSafeMode: true,
        },
        {
          label: t('Show explore tab in mobile app'),
          value: setValueTrueFalseUnset(patientFlags.patient_app_coaching_tab),
          onChange: (evt: any) => setFlagTrueFalseUnset('patient_app_coaching_tab', evt.target.value),
          onMouseOver: (evt: any) => setImageTrueFalseUnset('patient_app_coaching_tab', evt),
          options: [
            {
              value: 'unset',
              label: primaryClinic
                ? t('Clinic default ({{ defaultValue }})', {
                  defaultValue: getClinicDefault('patient_app_coaching_tab'),
                })
                : getClinicDefault('patient_app_coaching_tab'),
            },
            { value: 'false', label: t('Hide explore tab') },
            { value: 'true', label: t('Show explore tab') },
          ],
          description: t('Suggest recipes, meal suggestions, and articles in the RxFood app.'),
          visible: clinician.flags?.clinician_patient_allow_patient_app_coaching_tab_toggle,
          hideInSafeMode: true,
        },
        {
          label: t('Show mindful eating survey in mobile app'),
          value: setValueTrueFalseUnset(patientFlags.patient_app_mindful_eating_survey),
          onChange: (evt: any) => setFlagTrueFalseUnset('patient_app_mindful_eating_survey', evt.target.value),
          onMouseOver: (evt: any) => setImageTrueFalseUnset('patient_app_mindful_eating_survey', evt),
          options: [
            {
              value: 'unset',
              label: primaryClinic
                ? t('Clinic default ({{ defaultValue }})', {
                  defaultValue: getClinicDefault('patient_app_mindful_eating_survey'),
                })
                : getClinicDefault('patient_app_mindful_eating_survey'),
            },
            { value: 'false', label: t('Hide mindful eating survey') },
            { value: 'true', label: t('Show mindful eating survey') },
          ],
          visible: clinician.flags?.clinician_patient_allow_mindful_eating_survey_toggle,
          hideInSafeMode: false,
        },
      ].filter(setting => toggleSafeMode ? (setting.visible && !setting.hideInSafeMode) : setting.visible), // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      toggleSafeMode,
      isSafeMode,
      clinicDefaultMacroDisplay,
      patientFlags,
      clinician.flags?.clinician_patient_allow_macro_card_toggle,
      clinician.flags?.clinician_patient_allow_mindful_eating_survey_toggle,
      clinician.flags?.clinician_patient_allow_cal_carb_toggle,
      clinician.flags?.clinician_patient_allow_patient_app_coaching_tab_toggle,
      patientFlags.patient_app_show_macro_nutrition_card,
      primaryClinic,
      selectedNutrients,
      hideMealHoursStatus,
    ],
  );
  // Create a custom style hook to use for tooltips.
  const useStyles = makeStyles((theme) => ({
    tooltip: {
      fontSize: '1.2rem', // adjust the font size for tooltips here
    },
  }));

  return (
    <MDBContainer>
      <MDBModal backdrop={false} isOpen={props.show} toggle={props.toggle} centered size="lg">
        <MDBModalHeader toggle={props.toggle}>
          {titleText[displayMode]}
        </MDBModalHeader>
        <MDBModalBody>
          <Container>
            <Row>
              <Col className="verticalCenter">
                <Input
                  type="text"
                  label={t('First name')}
                  background
                  size="lg"
                  error={firstNameError}
                  value={firstName}
                  onChange={handleFirstNameChange}
                  onBlur={() => validate(validator.firstName)}
                />
              </Col>
              <Col className="verticalCenter">
                <Input
                  type="text"
                  label={t('Last name')}
                  background
                  size="lg"
                  error={lastNameError}
                  value={lastName}
                  onChange={handleLastNameChange}
                  onBlur={() => validate(validator.lastName)}
                />
              </Col>
            </Row>
            <Row>
              <Col className="verticalCenter">
                <Input
                  type="email"
                  label={t('Email')}
                  background
                  size="lg"
                  error={emailError}
                  value={email}
                  onChange={handleEmailChange}
                  onBlur={() => validate(validator.email)}
                />
              </Col>
              <Col className="verticalCenter">
                <Input
                  type="tel"
                  label={t('Phone number (optional)')}
                  background
                  size="lg"
                  error={phoneError}
                  value={phone}
                  onChange={handlePhoneChange}
                  onBlur={() => validate(validator.phone)}
                />
              </Col>
            </Row>
            <Row className={classnames({ 'd-none': displayMode !== 'add' })}>
              <Col>
                <Form.Group className="margin-m">
                  <Typeahead
                    id="clinicSelect"
                    labelKey="name"
                    options={_.sortBy(clinician.clinics, c => c.name?.toLowerCase())}
                    selected={(clinicId && clinician.clinics?.length === 1)
                      ? [clinician.clinics.find(c => c.id == clinicId)]
                      : undefined}
                    onChange={handleClinicChange}
                    placeholder={t('Select a clinic')}
                  />
                  <p className="errorMessage errorLabel">{clinicError}</p>
                </Form.Group>
              </Col>
            </Row>
            {appSettings.length > 0 && (
              <>
                <Row>
                  <Col>
                    <h5>
                      <Trans>App Settings</Trans>
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ width: '50%' }}>
                    {appSettings.map((setting, index) => (
                      <Row key={index} className="verticalCenter">
                        <Col
                          className="paddedText"
                          style={{ paddingTop: '0.2rem', paddingBottom: '0.5rem', width: '30%' }}
                          onMouseOver={setting.onMouseOver}
                        >
                          <Form.Label>{setting.label}</Form.Label>
                          {!setting.inputIsMultiselect
                            ? (
                              <MuiCustomSelect
                                value={setting.value}
                                onChange={setting.onChange as any}
                                onMouseOver={setting.onMouseOver}
                                disabled={!primaryClinic}
                                variant="outlined"
                                fullWidth
                              >
                                {!patientReq.isDone && (
                                  <MenuItem value="">
                                    <Trans>Loading...</Trans>
                                  </MenuItem>
                                )}
                                {patientReq.isDone
                                  && setting.options.map((option, index) => (
                                    <MenuItem key={index} value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  ))}
                              </MuiCustomSelect>
                            )
                            : (
                              <Autocomplete
                                fullWidth
                                multiple
                                disableClearable
                                disabled={selectedNutrients?.length >= 4}
                                options={setting.options as NutrientDef[]}
                                getOptionLabel={(option: NutrientDef) => option.label}
                                value={selectedNutrients ?? []}
                                renderTags={(value, getTagProps) =>
                                  value.map((option: NutrientDef, index) => (
                                    <Chip
                                      key={index}
                                      label={option?.label}
                                      {...getTagProps({ index })}
                                      disabled={false}
                                    />
                                  ))}
                                getOptionSelected={(option: NutrientDef, value: NutrientDef) =>
                                  option?.nutrient === value?.nutrient}
                                onChange={setting.onChange as any}
                                renderInput={(params) => (
                                  <div style={{ marginTop: '20px' }}>
                                    <MuiCustomTextField
                                      {...params}
                                      className="MuiAddUserTextField"
                                      id="keyMacronutrientsSelection"
                                      variant="outlined"
                                    />
                                  </div>
                                )}
                              />
                            )}
                          {setting.showTextInput && hideMealHoursStatus === 'on' && (
                            <div style={{ display: 'flex', gap: '10px', alignItems: 'flex-end' }}>
                              <Form.Control
                                type="number"
                                value={hideMealHours?.toString() || '0'}
                                onChange={(e: any) => {
                                  const hours = Number(e.target.value);
                                  if (isNaN(hours) || hours < 0) {
                                    return;
                                  }

                                  setHideMealHours(hours);
                                  setPatientFlags({
                                    ...patientFlags,
                                    patient_app_hide_meal_after_n_hours: String(hours),
                                  });
                                }}
                                style={{ marginTop: '10px', paddingLeft: '20px' }}
                                min="0"
                              />
                              <Form.Label>
                                <Trans>hours</Trans>
                              </Form.Label>
                            </div>
                          )}
                          <Form.Text className="text-muted">
                            {setting.description}
                          </Form.Text>
                        </Col>
                      </Row>
                    ))}
                  </Col>
                  <Col className="d-none d-lg-block">
                    <Image
                      src={imageToShow ?? frame}
                      style={{ width: '354px', height: '750px', objectFit: 'contain' }}
                    />
                  </Col>
                </Row>
              </>
            )}
            {primaryClinic?.flags?.hospital_patient_has_external_id && (
              <Row>
                <Col className="verticalCenter">
                  <Input
                    label={t('Patient External ID')}
                    background
                    value={patientExternalId}
                    onChange={handlePatientExternalIdChange}
                    size="lg"
                  />
                </Col>
              </Row>
            )}
            <ClinicPatientCustomFieldsInput
              clinic={primaryClinic}
              value={patientCustomFields}
              onChange={handlePatientCustomFieldsChange}
            />
            <div className={classnames({ 'd-none': displayMode !== 'add' })}>
              <Row>
                <Col
                  className="paddedText"
                  style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem', width: '30%', marginBottom: '20px' }}
                >
                  <h5 className="mb-3">
                    <Trans>Logging Period</Trans>
                  </h5>

                  {trialTypeOptions?.length > 0 && (
                    <>
                      <div className="mb-1">{t('Trial type:')}</div>
                      {trialType === 'none'
                        ? (
                          <StyledTooltip
                            title={t(
                              'Note: no report will be generated for patients without an initial logging period.',
                            )}
                            arrow
                            placement="top"
                          >
                            <Form.Control
                              as="select"
                              className="w-30"
                              style={{ width: '220px' }}
                              value={trialType}
                              onChange={(e) => setTrialType(e.currentTarget.value)}
                            >
                              {orderedTrialTypes.map((type) => (
                                <option value={type} key={type}>
                                  {type === 'none'
                                    ? t('no initial logging period')
                                    : getSubscriptionTypeOptions(t).find(
                                      (option) => option.id === type,
                                    )?.labelTr}
                                </option>
                              ))}
                            </Form.Control>
                          </StyledTooltip>
                        )
                        : (
                          <Form.Control
                            as="select"
                            className="w-30"
                            style={{ width: '220px' }}
                            value={trialType}
                            onChange={(e) => setTrialType(e.currentTarget.value)}
                          >
                            {orderedTrialTypes.map((type, i) => (
                              <option value={type} key={i}>
                                {type === 'none'
                                  ? t('no initial logging period')
                                  : getSubscriptionTypeOptions(t).find((option) => option.id === type)
                                    ?.labelTr}
                              </option>
                            ))}
                          </Form.Control>
                        )}
                    </>
                  )}

                  {trialType !== 'none' && (
                    <>
                      <div className="mb-1 mt-3" style={{ fontSize: '1.1rem' }}>
                        {trialType === 'single'
                          ? t('Number of days user should track for:')
                          : t('Number of days in each report:')}
                      </div>
                      {trialType === 'single'
                        ? (
                          <Form.Control
                            as="select"
                            className="w-30"
                            defaultValue={trialDays}
                            style={{ width: '220px', fontSize: '1.1rem' }}
                            onChange={(evt: any) => {
                              let trial_days = +evt.target.value;
                              if (isNaN(trial_days)) {
                                trial_days = 0;
                              }
                              setTrialDays(trial_days);
                            }}
                          >
                            {trialList.map((trialOption, i) => (
                              <option value={trialOption} key={i}>
                                {trialOption === 'none'
                                  ? t('No initial logging period')
                                  : trialOption + ' ' + t('day log')}
                              </option>
                            ))}
                          </Form.Control>
                        )
                        : (
                          <p style={{ fontSize: '0.9rem', fontStyle: 'bold' }}>
                            {trialType === 'weekly'
                              // eslint-disable-next-line i18next/no-literal-string
                              ? '7-day'
                              : trialType === 'bi-weekly'
                              // eslint-disable-next-line i18next/no-literal-string
                              ? '14-day'
                              : trialType === '4-weeks'
                              // eslint-disable-next-line i18next/no-literal-string
                              ? '28-day'
                              : trialType === 'monthly'
                              ? 'one-month'
                              : trialDays}
                          </p>
                        )}
                    </>
                  )}
                </Col>
              </Row>

              {(trialDays === 0 || trialType === 'none') && (
                <Row>
                  <Col>
                    <p style={{ fontStyle: 'italic' }}>
                      <Trans>
                        Note: no report will be generated for patients without an initial logging period.
                      </Trans>
                    </p>
                  </Col>
                </Row>
              )}

              {(trialDays > 0 && trialType !== 'none') && (
                <>
                  <Row>
                    <Col>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language}>
                        <DatePicker
                          inputVariant="outlined"
                          disableToolbar
                          format="YYYY-MM-DD"
                          label={t('Start Date')}
                          variant="inline"
                          shouldDisableDate={disablePastDates}
                          value={startDate}
                          onChange={handleStartDateChange}
                        />
                      </MuiPickersUtilsProvider>
                    </Col>
                    <Col>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language}>
                        {trialType === 'single'
                          ? (
                            <DatePicker
                              inputVariant="outlined"
                              onChange={() => {}}
                              disableToolbar
                              disabled
                              format="YYYY-MM-DD"
                              label={t('Estimated End Date')}
                              variant="inline"
                              value={singleTrialEndDate}
                            />
                          )
                          : (
                            <>
                              <StyledTooltip
                                title={t(
                                  'The patient will get a report on a {{trialType}} basis, if meals are logged, starting from {{startDate}} and ending at {{endDate}}.  The report will be availalbe within 48 hours after each reporting period.',
                                  {
                                    trialType,
                                    startDate: moment(startDate).format('MMMM Do, YYYY'),
                                    endDate: moment(multipleTrialEndDate).format('MMMM Do, YYYY'),
                                  },
                                )}
                                arrow
                                placement="top"
                              >
                                <span>
                                  <DatePicker
                                    inputVariant="outlined"
                                    onChange={handleMultipleTrialEndDateChange}
                                    disableToolbar
                                    format="YYYY-MM-DD"
                                    label={t('End Date')}
                                    variant="inline"
                                    value={multipleTrialEndDate}
                                    shouldDisableDate={(d) =>
                                      disableDatesNotInMultiple(
                                        d,
                                        startDate?.clone().toISOString().split('T')[0],
                                        trialType,
                                      )}
                                  />
                                </span>
                              </StyledTooltip>
                              <br />
                              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language}>
                                <DatePicker
                                  inputVariant="outlined"
                                  disableToolbar
                                  format="YYYY-MM-DD"
                                  label={t('Report Date')}
                                  variant="inline"
                                  disabled
                                  value={multipleTrialEndDate}
                                  style={{ display: 'none' }}
                                  onChange={() => {}}
                                />
                              </MuiPickersUtilsProvider>
                            </>
                          )}
                      </MuiPickersUtilsProvider>
                    </Col>
                  </Row>

                  {trialType === 'single'
                    ? (
                      <Row>
                        <Col className="padded-text verticalCenter mt-4">
                          <Trans>Report Delivery Date:</Trans>
                          <div style={{ fontSize: '13px', marginBottom: '15px' }}>
                            <Trans>
                              This is the date by which you need to receive the report. Adjust this so that it is on or
                              before the patient's next appointment date. If you don't know the next appointment date,
                              we suggest a date two weeks in the future. Reports will be generated if at least 2 days of
                              logging have been completed (at least 2 meals per day) and it is 48 hours prior to the
                              report date.
                            </Trans>
                          </div>
                          <div style={{ fontSize: '10px', marginBottom: '15px' }}>
                            {t(
                              'Reports will be generated if at least 2 days of logging have been completed (> 2 meals per day) and it is < 48 hours prior to the report date.',
                            )}
                          </div>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language}>
                            <DatePicker
                              inputVariant="outlined"
                              disableToolbar
                              format="YYYY-MM-DD"
                              label={t('Report Date')}
                              variant="inline"
                              shouldDisableDate={disableInvalidReportDates}
                              value={reportDate}
                              onChange={handleReportDateChange}
                            />
                          </MuiPickersUtilsProvider>
                          <p className="errorMessage errorLabel">{reportDateError}</p>
                        </Col>
                      </Row>
                    )
                    : (
                      startDate && trialType !== 'single' && (
                        <>
                          <Row>
                            <Col>
                              <div className="mt-3 mb-1">{t('All Start Dates:')}</div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {futureStartDates.map((date, i) => <div key={i}>{date}</div>)}
                            </Col>
                          </Row>
                        </>
                      )
                    )}
                </>
              )}
            </div>

            {saveRes.isError && (
              <Row>
                <Col>
                  <p className="errorMessage errorLabel">
                    <Trans>Error saving patient</Trans>
                    {': ' + saveRes.error || t('Unknown error')}
                  </p>
                </Col>
              </Row>
            )}
            <Row>
              <Col style={{ marginTop: '-10px' }}>
                <p className="margin-l">
                  <Button onClick={handleButtonClick} disabled={saveRes.isPending} block>
                    {buttonText[displayMode]}
                  </Button>
                </p>
              </Col>
            </Row>
            <Row className={classnames({ 'd-none': displayMode !== 'add' })}>
              <Col>
                <p className="margin-bot-m">
                  <Trans>
                    By registering you agree to RxFood's{' '}
                    <Link className="btn-link" to="https://rxfood.co/privacy-policy">Privacy policy</Link> and{' '}
                    <Link className="btn-link" to="https://rxfood.co/terms-and-conditions">terms & conditions</Link>
                  </Trans>
                </p>
              </Col>
            </Row>
            <Row className={classnames({ 'd-none': true })}>
              <Col>
                <p className="margin-bot-m text-center">
                  <span className="warning">
                    <i className="fas fa-trash-alt" />
                    <Trans>delete patient</Trans>
                  </span>
                </p>
              </Col>
            </Row>
          </Container>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};
